import {encode as btoa} from 'base-64';

const axios = require('axios');
let SERVER_URL = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    SERVER_URL = "http://127.0.0.1:8000";
} else {
    SERVER_URL = "https://agua.wotbi.com";
}

let token = null;
export let api = null;

export function checkHttpStatus(response) {
    // console.log("Status: ", response.status);
    // console.log("Status: ", response);
    // console.log("Status: ", response.data);
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    // console.log("Check http: ", response.data);
    const error = new Error(response.data);
    error.response = response;
    throw error;
}

export function parseJSON(response) {
    return response.data;
}

export function initApi() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (token === null || token !== user.token) {
        api = axios.create({
            baseURL: SERVER_URL,
            timeout: 5000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${user.token}`
            },
        });
        token =  user.token;
    }
}

const requests = {
    get: url => api.get(url).then(checkHttpStatus).then(parseJSON),
    put: (url, body) => api.put(url, body).then(checkHttpStatus).then(parseJSON),
    patch: (url, body) => api.patch(url, body).then(checkHttpStatus).then(parseJSON),
    post: (url, body) => api.post(url, body).then(checkHttpStatus).then(parseJSON)
}


export const Users = {
    login: (user, password) => {
        const auth = btoa(`${user}:${password}`);
        return axios.post(SERVER_URL + '/api/v1/login/', {}, {headers: {'Authorization': `Basic ${auth}`}}).then(checkHttpStatus).then(parseJSON)
    },
}

export const Cashbox = {
    open: (caja, importeApertura) => requests.post("/api/v1/cajas/apertura/", {caja, importeApertura}),
    close: (id, importeCierre) => requests.patch("/api/v1/cajas/" + id + "/cierre/", {importeCierre, estado: 2}),
    isOpen: (id) => requests.get("/api/v1/cajas/" + id + "/abierta/"),
    getAll: () => requests.get("/api/v1/cajas/"),
    history: () => requests.get("/api/v1/cajas/historial/"),
    transaction: (id) => requests.get("/api/v1/cajas/" + id + "/transacciones/"),
    addSale: (recibido, importe, apertura, documentos) => requests.post("/api/v1/venta/", {recibido, importe, apertura, documentos})
}

export const Invoices = {
    get: (id) => requests.get("/api/v1/factura/" + id + "/"),
    ticket: (transactionId) => requests.get(`/api/v1/transacciones/${transactionId}/ticket`)
}

export const Budget = {
    get: (id) => requests.get("/api/v1/presupuesto/" + id + "/")
}